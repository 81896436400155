<template>
  <div>
    <v-row>
      <!-- SELECT BOX: account/pobocka -->
      <v-col>
        <v-select
          v-model="account"
          :items="items_accounts"
          item-text="name"
          item-value="id"
          label="Zvolte účet/pobočku"
          :loading="account_loading"
          clearable
          multiple
        >
          <template v-slot:item="{ item }">
            {{ item.name }} <span style="font-size: 10px;color:#BDBDBD;margin-left:15px;"><i>{{ item.desc }}</i></span>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 2">
              <span>{{ item.name }}</span>
            </v-chip>
            <span
              v-if="index === 2"
              class="text-grey text-caption align-self-center"
            >
              (+{{ account.length - 2 }} další účty...)
            </span>
          </template>
        </v-select>
      </v-col>
      <!-- SELECT BOX: schedule/harmonogram -->
      <v-col>
        <v-select
          v-model="schedule"
          :items="items_schedules"
          item-text="name"
          item-value="id"
          label="Zvolte harmonogram"
          @change="is_filter_set = true"
          :loading="schedule_loading"
          clearable
          multiple
        >
          <template v-slot:item="{ item }">
            {{ item.name }} <span style="font-size: 10px;color:#BDBDBD;margin-left:15px;"><i>{{ item.group }}</i></span>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 2">
              <span>{{ item.name }}</span>
            </v-chip>
            <span
              v-if="index === 2"
              class="text-grey text-caption align-self-center"
            >
              (+{{ schedule.length - 2 }} další harmonogramy...)
            </span>
          </template>
        </v-select>
      </v-col>
      <!-- DATUM OD -->
      <v-col>
        <v-menu
          v-model="date_from_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Zvolte datum(nebo rozsah) od"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date_from"
            selected-items-text="Rozsah"
            format="DD.MM.YYYY"
            locale="cs"
            range
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="date_from_menu = false"
            >
              Zrušit
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="date_from_menu = false"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-card>
      <!-- TABULKA -->
      <v-data-table
        ref="supersaasTable"
        :headers="tableHeadersWithoutColumn"
        :items="table_list"
        :items-per-page="table_pagination.itemsPerPage"
        :sort-by.sync="table_sort_by"
        :sort-desc.sync="table_sort_desc"
        @update:options="tableOptions"
        class="elevation-1"
        style="min-height:300px"
        locale="cs-CS"
        show-expand
        multi-sort
        show-sort-icon
        calculate-widths
        :loading="table_list_loading"
        loading-text="Hledám udalosti harmonogramu, prosím o strpění..."
        :options.sync="options"
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-options': [5, 15, 30, 50, 100, 500, 1000],
          itemsPerPageText: 'Počet položek na stránku',
        }"
        @pagination="tablePagination"
      >
        <!-- suctovy radek | v-slot:footer="{widths}" -->
        <template v-slot:footer="{}">
          <div>
            <table style="width:calc(100%) - 16px;background-color: #FAFAFA;">
              <tr>
                <td v-for="(column, i) in bottom_header_width" :style="{ width: `calc(${column.width}px)`, padding: '0px 16px' }" :key="i">
                  <div
                    v-if="column.text==='Kapacita' || column.text==='Celkem' || column.text==='Rozdíl'  || column.text === 'Čekající'"
                    style="text-align:center;font-size:14px;font-weight: bold;"
                  >
                  <span :title="`Celkem: ${countColumn(column.text, 'sum_total')}`">{{ countColumn(column.text, 'sum') }}</span>
                </div>
                </td>
              </tr>
            </table>
          </div>
        </template>
        <!-- ROZSIRENNE DATA PRE VNORENU TABULKU  -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <!-- POPIS -->
            <v-row style="padding:20px;" v-if="item.description && item.description.length > 0">
              <v-col>
                <h3 style="color:#7B1FA2;margin-bottom: 25px;">
                  <v-icon style="color:#7B1FA2;padding-bottom: 5px;margin-right: 10px">
                    {{ icons.mdiFileDocumentEditOutline }}
                  </v-icon>
                  POPIS
                </h3>
                <v-row>
                  <div style="margin-left: 15px;">{{ item.description }}</div>
                </v-row>
              </v-col>
            </v-row>
            <!-- UCHAZEČI - REZERVACE -->
            <v-row style="padding:20px;">
              <v-col>
                <h3 v-if="item.bookings && item.bookings.length > 0" style="color:#7B1FA2;margin-bottom: 25px;">
                  <v-icon style="color:#7B1FA2;padding-bottom: 5px;margin-right: 10px">
                    {{ icons.mdiAccountGroup }}
                  </v-icon>
                  UCHAZEČI - REZERVACE
                </h3>
                <v-row v-if="item.bookings && item.bookings.length > 0">
                  <v-col :cols="12">
                    <v-row>
                      <!-- TOTAL(obsadeny) -->
                      <v-col :span="12">
                        <b>Obsazeno</b>
                        <div v-for="book in item.bookings" v-bind:key="book.id" style="margin-left: 15px;">
                          <div v-if="book.waitlisted === false">
                            <span style="display:block;width:200px;color:#424242;">{{ book.full_name }} </span>
                            <span style="font-size: 11px;">{{ book.created_by }}</span>
                          </div>
                        </div>
                      </v-col>
                      <!-- WAITLISTED(cakajuci) -->
                      <v-col :span="12">
                        <b v-if="isAnyWaitListed(item.bookings)">Čekajíci</b>
                        <div v-for="book in item.bookings" v-bind:key="book.id" style="margin-left: 15px;">
                          <div v-if="book.waitlisted === true">
                            <span style="display:block;width:200px;color:#424242;">{{ book.full_name }} </span>
                            <span style="font-size: 11px;">{{ book.created_by }}</span>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col style="text-align:center;">
                    Smena zatiaľ nebola nikým obsadená ...
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </td>
        </template>
        <!-- UPRAVA NIEKTORYCH COLUMNS -->
        <!-- <template v-slot:item.waitlisted="{ item }">
          <v-chip
            :color="getWaitlistedColor(item.waitlisted)"
            dark
          >
            {{ item.waitlisted }}
          </v-chip>
        </template> -->
        <template v-slot:item.total="{ item }">
          <v-chip
            :color="getTotalColor(item.total, item.capacity)"
            dark
          >
            {{ item.total }}
          </v-chip>
        </template>
        <template v-slot:item.rozdiel="{ item }">
          <span title="Hodnota ukazuje rozdiel medzi stlpcom kapacita a celkem." style="cursor:help">
            <v-chip
              v-if="item.rozdiel > 0"
              :color="getRozdielColor(item.rozdiel)"
              dark
            >
              {{ item.rozdiel }}
            </v-chip>
            <span v-else>{{ item.rozdiel }}</span>
          </span>
        </template>
        <template v-slot:item.start="{ item }">
          <span style="font-size: 10px;margin-right:5px;">{{ handleDate(item.start) }}</span>
          <span>{{ handleTime(item.start) }}</span>
        </template>
        <template v-slot:item.finish="{ item }">
          <span style="font-size: 10px;margin-right:5px;">{{ handleDate(item.start) }}</span>
          <span>{{ handleTime(item.finish) }}</span>
        </template>
        <template v-slot:item.title="{ item }">
          <span v-if="item.description && item.description.length > 0" title="Popis je k dispozici.">
            <v-icon x-small style="margin-right: 8px;cursor:help;">
              {{ icons.mdiFileDocumentEditOutline }}
            </v-icon>
          </span>
          <span style="font-size: 12px;margin-right:5px;color:#424242">{{ item.title }}</span>
        </template>
        <template v-slot:item.account="{ item }">
          <span style="font-size: 10px;margin-right:5px;">{{ item.account }}</span>
        </template>
        <template v-slot:item.harmonogram="{ item }">
          <span style="font-size: 10px;margin-right:5px;">{{ item.harmonogram }}</span>
        </template>
      </v-data-table>
      <!-- btn EXPORT + dialog EXPORT -->
      <v-btn
        :disabled="(table_list && table_list.length === 0) || (table_list_loading)"
        color="primary"
        fab
        top
        right
        absolute
        title="Export do excelu"
        @click="show_dialog = true"
        style="top:-25px;right:10px">
        <v-icon>
          {{ icons.mdiExportVariant }}
        </v-icon>
      </v-btn>
      <v-dialog
        width="400"
        v-model="show_dialog"
        bottom>
        <v-card>
          <v-card-title>
            <span class="headline">Nastavenie exportu</span>
          </v-card-title>
          <v-card-text>
            <v-container
              class="px-0"
              fluid
            >
              <v-radio-group v-model="radio_export">
                <v-radio
                  v-for="(n, index) in ['standardný export', 'export včetne obsadenosti', 'export pro virtuální callcentrum', 'export denní obsazenosti', 'sumár obsadenosti']"
                  :key="index"
                  :label="`${n}`"
                  :value="index"
                ></v-radio>
              </v-radio-group>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col style="text-align:right">
                <v-btn :disabled="export_loading" text @click="show_dialog = false">Zrušit</v-btn>
                <v-btn :loading="export_loading" color="primary" text @click="exportToExcel()">Export</v-btn>
            </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- btn ZRUS sortovanie -->
      <v-btn
        v-if="table_is_sorting"
        small
        color="#BDBDBD"
        fab
        top
        right
        absolute
        title="Zrušiť setřídení."
        @click="clearSort"
        style="top:-18px;right:70px">
        <v-icon>
          {{ icons.mdiSortVariantRemove }}
        </v-icon>
      </v-btn>
      <!-- btn ZRUS filter -->
      <v-btn
        v-if="(account && account.length > 0) || (schedule && schedule.length > 0)"
        small
        color="#BDBDBD"
        fab
        top
        right
        absolute
        title="Zrušiť nastavení filtru."
        @click="clearFilter"
        :style="`top:-18px;right:${table_is_sorting ? '115px' : '70px'}`">
        <v-icon>
          {{ icons.mdiFilterRemoveOutline }}
        </v-icon>
      </v-btn>
      <!-- btn RUN -->
      <v-btn
        v-show="is_filter_set"
        :disabled="!is_filter_set"
        fab
        right
        large
        title="Získej data ze Supersaas..."
        color="#388E3C"
        style="position:fixed;bottom:60px;right:40px;color:white;z-index:99999"
        :loading="table_list_loading"
        @click="fetchScheduleEvent(schedule)">
        <v-icon
        style="margin-left:5px;"
        large
        >
          {{ icons.mdiPlayOutline }}
        </v-icon>
      </v-btn>
    </v-card>

    <!-- SNACKBAR -->
    <snack-bar
      :snackbar="snackbar"
      :color="snack_color"
      :text="snack_text"
      @close="snackbar=false"
    >
    </snack-bar>
  </div>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint no-underscore-dangle: "off" */
/* eslint no-unused-vars: "off" */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
/* eslint no-plusplus: "off" */
/* eslint prefer-const: "off" */
/* eslint no-param-reassign: "off" */
/* eslint padded-blocks: "off" */
/* eslint object-curly-newline: "off" */
/* eslint no-else-return: "off" */
/* eslint no-lonely-if: "off" */
/* eslint prefer-arrow-callback: "off" */

import { mdiAccountGroup, mdiFileDocumentEditOutline, mdiSortVariantRemove, mdiExportVariant, mdiPlayOutline, mdiFilterRemoveOutline, mdiContrastCircle } from '@mdi/js'
import axios from 'axios'
import response from '@/mixins/response'
import SnackBar from '@/components/snackbar/index.vue'

export default {
  components: {
    'snack-bar': SnackBar,
  },
  mixins: [response],
  data() {
    return {
      hideSecondColumn: false,
      table_headers: [
        { text: 'Čas začátku', align: 'start', value: 'start' },
        { text: 'Čas konce', align: 'start', value: 'finish' },
        { text: 'Název', value: 'title' },
        { text: 'Popis', value: 'description' },
        { text: 'Účet', align: 'center', value: 'account' },
        { text: 'Harmonogram', align: 'center', value: 'harmonogram' },
        { text: 'Kapacita', align: 'center', value: 'capacity' },
        { text: 'Celkem', align: 'center', value: 'total' },
        { text: 'Rozdíl', align: 'center', value: 'rozdiel' },
        { text: 'Čekající', align: 'center', value: 'waitlisted' },
        { text: '', value: 'data-table-expand', sortable: false },
      ],
      table_list: [],
      table_list_loading: false,
      table_pagination: {
        page: 1,
        itemsPerPage: 15,
      },
      table_sort_by: [],
      table_sort_desc: [],
      table_is_sorting: false,
      account: [],
      account_loading: false,
      schedule: undefined,
      schedule_loading: false,
      date_from: [
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      ],
      date_from_menu: false,
      event_id: undefined,
      items_all_schedules: [],
      items_schedules: [],
      items_accounts: [],
      snackbar: false,
      snack_color: 'red',
      snack_text: '',
      options: {},
      show_dialog: false,
      radio_export: 0,
      export_loading: false,
      modal: false,
      is_filter_set: false, // ked dojde k zmene filtra, rozsvieti sa button filtrovat
      schedule_init: undefined, // iba pri prvtnej inicializacii, ked sa vstupy na stranku
      bottom_header_width: [], // suctovy riadok na spodku tabulky, tu su sirky jednotlivych stlpcov

      // Icons
      icons: {
        mdiAccountGroup,
        mdiFileDocumentEditOutline,
        mdiSortVariantRemove,
        mdiExportVariant,
        mdiPlayOutline,
        mdiFilterRemoveOutline,
      },
    }
  },
  computed: {
    tableHeadersWithoutColumn() {
      // Vráti pole table_headers s vylúčením stĺpcov
      if (this.schedule && this.schedule[0] === 'all') {
        let columns = this.table_headers.filter(header => header.value !== 'description')

        return columns
      } else {
        let columns = this.table_headers.filter(header => header.value !== 'description')
        if (this.account && this.account.length < 2) {
          columns = columns.filter(header => header.value !== 'account')
        }
        if (this.schedule && this.schedule.length < 2) {
          columns = columns.filter(header => header.value !== 'harmonogram')
        }

        return columns
      }
    },
    dateRangeText() {
      let tmp = this.date_from
      let first = (this.date_from[0] && this.$moment(this.date_from[0]).format('DD.MM.YYYY')) || this.$moment(new Date()).format('DD.MM.YYYY')
      let second = (this.date_from[1] && this.$moment(this.date_from[1]).format('DD.MM.YYYY')) || undefined
      tmp = [first]
      if (second) {
        tmp.push(second)
      }
      if (this.date_from && this.date_from.length > 1) {
        if (this.$moment(this.$moment(this.date_from[1])).isBefore(this.$moment(this.date_from[0]))) {
          first = this.date_from[0] && this.$moment(this.date_from[0]).format('DD.MM.YYYY')
          second = this.date_from[1] && this.$moment(this.date_from[1]).format('DD.MM.YYYY')
          tmp = [second, first]
        }
      }

      return tmp.join(' ~ ') // this.date_from.join(' ~ ')
    },
  },
  watch: {
    date_from() {
      if (this.date_from && this.date_from.length > 1) {
        if (this.$moment(this.$moment(this.date_from[1])).isBefore(this.$moment(this.date_from[0]))) {
          let first = this.date_from[0]
          let second = this.date_from[1]
          this.date_from[0] = second
          this.date_from[1] = first
        }
      }

      // ---
      if (this.schedule.length === 0) {
        this.is_filter_set = false
      } else {
        this.is_filter_set = true
      }
    },
    account(new_val) {
      if (new_val && new_val.length > 0) {
        this.items_schedules = [{ header: '.:: účet/pobočka' }, { id: 'all', name: 'všechny harmonogramy', group: new_val.toString() }, { divider: 1 }, { header: '.:: harmonogram' }]
        for (let index = 0; index < this.items_all_schedules.length; index++) {
          const item = this.items_all_schedules[index]
          if (new_val.indexOf(item.group) > -1) {
            this.items_schedules.push(item)
          }
        }
      } else {
        this.items_schedules = this.items_all_schedules
      }
      this.schedule = this.schedule_init ? this.schedule : ''
      this.schedule_init = undefined
    },
    schedule(new_val) {
      for (let index = 0; index < this.items_schedules.length; index++) { // vsetko enable
        this.items_schedules[index].disabled = false
      }
      if (new_val.indexOf('all') > -1) {
        for (let index = 0; index < this.items_schedules.length; index++) { // okrem all je vsetko disable
          if (this.items_schedules[index].id !== 'all') {
            this.items_schedules[index].disabled = true
          }
        }
      } else if (new_val.length > 0) {
        this.items_schedules[1].disabled = true // all je disable
      }

      // ---
      if (this.schedule.length === 0) {
        this.is_filter_set = false
      }
    },
    snackbar(new_val) {
      if (new_val === true) {
        setTimeout(() => {
          this.snackbar = false
        }, 8000)
      }
    },
    table_list() {
      this.refreshBottomRow()
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize)
  },
  mounted() {
    window.addEventListener('resize', this.handleWindowResize)
  },
  created() {
    let settings = JSON.parse(localStorage.getItem(`supersaas_schedule_${this.$store.getters.getProfile._id}`))
    settings = settings || {
      id: ['all'],
    }
    settings.id = Array.isArray(settings.id) ? settings.id : []
    this.fetchAccountList(settings.id)

    this.schedule_init = settings.group || []
    this.schedule_init = Array.isArray(this.schedule_init) ? this.schedule_init : []

    let sort = JSON.parse(localStorage.getItem(`supersaas_sort_${this.$store.getters.getProfile._id}`))
    if (sort) {
      this.table_sort_by = sort.sortBy || []
      this.table_sort_desc = sort.sortDesc || []
    }
  },
  updated() {
  },
  methods: {
    handleWindowResize() {
      this.getWidthOfColumnHeaders()
    },
    async fetchAccountList(p_harmongram_id) {
      this.account_loading = true
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/supersaas/account/list`,
        data: undefined,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }
      const resp = await axios.get(config.url, config)
      this.items_accounts = resp.data.db_data
      this.fetchScheduleList(p_harmongram_id)
      this.account_loading = false
    },
    async fetchScheduleList(p_harmongram_id) {
      this.schedule_loading = true

      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/supersaas/schedule/list`,
        data: undefined,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }
      const resp = await axios.get(config.url, config)
      this.items_schedules = resp.data.db_data
      this.items_all_schedules = resp.data.db_data
      this.account = this.schedule_init ? [...this.schedule_init] : this.account
      if (p_harmongram_id) {
        this.schedule = p_harmongram_id
      }
      this.schedule_loading = false

      if (this.schedule && this.schedule.length > 0) {
        this.is_filter_set = true
      }
    },
    async fetchScheduleEvent(p_event_id, p_export) {
      this.table_list_loading = true
      this.event_id = p_event_id
      let account = this.account || []
      let name
      let lists = []
      if (p_event_id.indexOf('all') === -1) {
        /* eslint-disable-next-line no-plusplus */
        for (let index = 0; index < this.items_schedules.length; index++) {
          if (p_event_id.indexOf(this.items_schedules[index].id) > -1) {
            const _account = this.items_schedules[index].group
            name = this.items_schedules[index].name // nazov harmongramu
            lists.push({ account: _account, schedule_id: this.items_schedules[index].id, name: name })
          }
        }
      } else {
        lists = [{ account: '-1', schedule_id: 'all' }] // vsetky harmonogramy za vsetky ucty
        for (let index = 0; index < account.length; index++) { // ... alebo konkretny ucet/y a vsetky harmonogramy
          lists[index] = { account: account[index], schedule_id: 'all' }
        }
      }
      let param_export = p_export ? `&export=${p_export}` : ''

      let filter_date = this.date_from && this.date_from.length > 1 ? `${this.$moment(this.date_from[0], 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss')},${this.$moment(this.date_from[1], 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss')}` : this.$moment(this.date_from[0], 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss')

      // POZNAMKA!!! supersaas nema momentalne zapracovane v API, aby vracalo celkovy pocet smien, takze je na serveri natvrdo zadefinovany limit poctu vratenych smien na 9999, hodnoty page a items_per_page sa nevyuzivaju...
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/supersaas/schedule/list/fetch/?lists=${JSON.stringify(lists)}&page=${this.table_pagination.page}&items_per_page=${this.table_pagination.itemsPerPage}&from=${filter_date}${param_export}`,
        data: undefined,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
        responseType: p_export ? 'blob' : undefined,
      }
      const resp = await axios.get(config.url, config)
      if (p_export) {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${p_event_id}.xlsx`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.export_loading = false
        this.show_dialog = false
      } else {
        this.is_filter_set = false
        this.table_list = resp.data.db_data
        localStorage.setItem(`supersaas_schedule_${this.$store.getters.getProfile._id}`, JSON.stringify({ id: this.schedule, name: name || 'vše', group: account }))
      }
      this.table_list_loading = false
    },
    getTotalColor(p_total, p_capacity) {
      if (p_total === p_capacity) {
        return '#4CAF50'
      } else if (p_total > 0) {
        return '#FF9800'
      }

      return '#F44336'
    },
    getWaitlistedColor(p_waitlistedcolor) {
      if (p_waitlistedcolor > 0) {
        return '#4CAF50'
      }

      return '#F44336'
    },
    getRozdielColor(p_rozdiel) {
      if (p_rozdiel > 0) {
        return '#F44336'
      }

      return ''
    },
    isAnyWaitListed(p_bookings) {
      for (let index = 0; index < p_bookings.length; index++) {
        const book = p_bookings[index]
        if (book.waitlisted === true) {
          return true
        }
      }

      return false
    },
    tablePagination(p_param) {
      this.table_pagination = p_param
      this.refreshBottomRow()

      // this.fetchScheduleEvent(this.event_id)
    },
    handleDate(p_datetime) {
      return this.$moment(p_datetime, 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY')
    },
    handleTime(p_datetime) {
      return this.$moment(p_datetime, 'YYYY-MM-DD HH:mm').format('HH:mm')
    },
    formatDate(date) {
      if (!date) return null
      let arr = []
      const [year, month, day] = date[0].split('-')
      if (date[0]) {
        arr.push(`${day}.${month}.${year}`)
      }
      if (date[1]) {
        const [year1, month1, day1] = date[1].split('-') || ''
        arr.push(`${day1}.${month1}.${year1}`)
      }

      return arr
    },
    exportToExcel() {
      // this.snack_text = 'Na tejto funkcii sa hustě krutě pracuje ;)'
      // this.snack_color = 'red'
      // this.snackbar = true

      this.export_loading = true
      this.fetchScheduleEvent(this.event_id, `xls&export_type=${this.radio_export}`)
    },
    clearSort() {
      this.table_sort_by = []
    },
    clearFilter() {
      this.account = []
      this.schedule = []
      this.date_from = [
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      ]
      this.table_list = []
    },
    tableOptions(p_opt) {
      this.table_is_sorting = p_opt.sortBy.length > 0
      localStorage.setItem(`supersaas_sort_${this.$store.getters.getProfile._id}`, JSON.stringify({
        sortBy: p_opt.sortBy,
        sortDesc: p_opt.sortDesc,
      }))
    },
    refreshBottomRow() {
      setTimeout(() => { // treba premysliet, zatial takto trapne
        this.getWidthOfColumnHeaders()
      }, 1000)
    },
    getWidthOfColumnHeaders() {
      const dataTable = this.$refs.supersaasTable
      if (dataTable) {
        const headers = dataTable.$props.headers

        const columnHeaders = document.querySelectorAll('th[role="columnheader"]')

        if (columnHeaders) {
          this.bottom_header_width = Array.from(columnHeaders).map((th, index) => ({ width: th.offsetWidth, text: headers[index].text }))
        }
      }
    },
    countColumn(p_column_title, p_type) {
      if (this.table_list.length === 0) { // bez dat nepocitaj...
        return ''
      }

      // ---
      let column = ''
      switch (p_column_title) {
        case 'Kapacita':
          column = 'capacity'
          break
        case 'Celkem':
          column = 'total'
          break
        case 'Rozdíl':
          column = 'rozdiel'
          break
        case 'Čekající':
          column = 'waitlisted'
          break
        default:
          column = p_column_title
      }

      // ---
      let sum = 0 // aktualne zobrazena strana
      let sum_total = 0 // celkom dat v tabulke
      for (let index = 0; index < this.table_list.length; index++) {
        const row = this.table_list[index]
        if (index >= this.table_pagination.pageStart && index < this.table_pagination.pageStop) {
          sum += row[column]
        }
        sum_total += row[column]
      }

      return p_type === 'sum_total' ? sum_total : sum
    },
  },
}
</script>

<style>
  .hidden {
    display: none;
    width: 0;
  }
</style>
